export const directFormStyles = `
  .header {
    .label {
      color: var(--text-secondary-light);
      font-size: 12px;
    }

    .back-link {
      position: relative;
      top: 2px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 22px;
      height: 22px;
      margin-right: 20px;
      color: var(--text-primary);

      svg {
        max-width: 100%;
        max-height: 100%;
      }
    }

    h2 {
      display: inline-block;
      position: relative;
      top: 2px;
      left: -8px;
      margin: 0;
    }

    h4 {
      display: inline-block;
      margin: 0;
      font-size: 20px;
    }
  }

  .form-page-list {
    margin: 8px 0 24px;

    .form-page {
      display: inline-block;

      > span {
        display: inline-block;
        text-align: center;

        &:before {
          display: block;
          content: attr(title);
          font-weight: bold;
          height: 0;
          overflow: hidden;
          visibility: hidden;
        }
      }

      &.active {
        font-weight: bold;
      }

      .separator {
        width: 7px;
        margin: 0 8px;
        color: var(--text-secondary-light);
        transform: rotate(180deg);
      }

      &:last-of-type {
        svg {
          display: none;
        }
      }
    }
  }

  form {
    h5 {
      margin-top: -1.5rem;
      margin-bottom: 2rem;
    }

    h5 + .hint {
      font-size: 10px;
      color: var(--text-secondary);
    }

    .form-control {
      background: var(--body-background);
    }

    .form-separator {
      height: 3rem;
    }

    div.label {
      color: var(--text-secondary);
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      white-space: nowrap;
      pointer-events: none;
      transform: translate(0px, 0px) scale(0.7);
      transform-origin: top left;
    }
  }

  .form-actions {
    text-align: right;
    margin-top: 30px;

    &.spread {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
  }
`
