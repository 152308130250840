export const rootPath = () => '/'

export const publishersPath = () => '/partners'
export const publisherPath = (uuid, tab = '') =>
  `/partners/${uuid}${tab ? `#${tab}` : ''}`

export const newPublisherPath = () => '/partners/new'
export const newPublisherContractPath = (publisherUuid) =>
  `/partners/${publisherUuid}/new`
export const editPublisherContractPath = (publisherUuid, contractUuid) =>
  `/partners/${publisherUuid}/${contractUuid}/edit`

export const publisherContractShortlinksPath = (publisherUuid, contractUuid) =>
  `/smartlinks/${publisherUuid}/${contractUuid}`
export const newPublisherContractShortlinkPath = (
  publisherUuid,
  contractUuid
) => `/smartlinks/${publisherUuid}/${contractUuid}/new`
export const editPublisherContractShortlinkPath = (
  publisherUuid,
  contractUuid,
  shortlinkUuid
) => `/smartlinks/${publisherUuid}/${contractUuid}/${shortlinkUuid}/edit`

export const conversionsPath = () => '/conversions'

export const billingPath = () => '/billing'

export const accountPath = () => '/account'

export const signupFormPath = () => '/signup'

export const helpMajorArticlesPath = () => '/help/platforms'
export const helpMinorArticlesPath = () => `/help/other`
export const helpCmsArticlePath = (cms) => `/help/cms/${cms}`
export const helpInstructionsPath = (cms) => `/help/send-instructions/${cms}`
export const helpArticlePath = (slug) => `/help/${slug}`

export const forgotPasswordPath = () => '/forgot-password'

export const errorPath = () => '/404'

export const logoutPath = () => '/logout/'

export const invoiceUrl = (advertiserUuid, invoiceUuid) =>
  `${process.env.BACKEND_HOST}/connect/v1/invoice/${advertiserUuid}/${invoiceUuid}.pdf`

export const compare = (currentPath, comparePath) => {
  if (!currentPath || !comparePath) {
    return false
  } else if (comparePath.includes('*')) {
    return currentPath.match(
      new RegExp(`^${comparePath.replace(/\*/g, '([^/]+)')}$`)
    )
  } else {
    return currentPath === comparePath
  }
}
