import { authToken, logout, getCurrentWebsite, getContractCode } from './auth'
import { toQuery } from '../utils/helpers'

const apiHost = process.env.BACKEND_HOST
const apiPathPrefix = '/connect/v1'

const progress = {}
const retryDelay = 1500

const apiFetch = (path, options) => {
  if (progress[path]) {
    return Promise.resolve({
      status: 0,
      data: undefined,
      message: '',
    })
  }
  progress[path] = true

  options = options || {}
  options.method = options.method || 'GET'
  options.headers = {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
    'X-Access-Token': authToken(),
    'X-Website': getCurrentWebsite(),
    'X-Contract-Code': getContractCode(),
  }

  return window
    .fetch(`${apiHost}${apiPathPrefix}${path}`, options)
    .then((raw) => {
      authorize(raw.status)
      progress[path] = false
      return raw.json()
    })
    .catch((error) => {
      console.error(`${path}:`, error)
      setTimeout(() => {
        progress[path] = false
      }, retryDelay)
    })
}

let unauthCount = 0
const maxUnauth = 2
const authorize = (status) => {
  if (status === 401) {
    unauthCount++
    if (unauthCount >= maxUnauth) {
      logout()
    }
  } else {
    unauthCount = 0
  }
}

export const fetchCurrentUser = (params) =>
  apiFetch(`/advertiser/me?${toQuery(params)}`)

export const getRequest = (path) => apiFetch(path)

export const postRequest = (path, body) =>
  apiFetch(path, {
    method: 'POST',
    body: JSON.stringify(body),
  })

export const putRequest = (path, body) =>
  apiFetch(path, {
    method: 'PUT',
    body: JSON.stringify(body),
  })

export const deleteRequest = (path) =>
  apiFetch(path, {
    method: 'DELETE',
  })
