import money from 'money-math'
import { convertMoney } from '.'

export const sumInt = (values, field) =>
  values.reduce((sum, value) => sum + parseInt(value[field]), 0)

export const sumFloat = (values, field) =>
  values.reduce((sum, value) => sum + parseFloat(value[field]), 0)

export const avgFloat = (values, field) =>
  sumFloat(values, field) / (values.length || 1)

export const formatStatValue = (value, float = false) => {
  if (value === undefined) {
    return '-'
  } else if (isNaN(value)) {
    return '0'
  } else if (value === 0) {
    return '0'
  } else if (value) {
    if (float) {
      return formatStatFloatValue(parseFloat(value))
    } else {
      return formatStatIntValue(parseInt(value))
    }
  } else {
    return '-'
  }
}

const formatStatIntValue = (value) =>
  value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')

const formatStatFloatValue = (value) =>
  value
    .toFixed(2)
    .replace('.', ',')
    .replace(/\B(?=(?=\d*,)(\d{3})+(?!\d))/g, '.')

export const formatStatPercentage = (value) =>
  `${(parseFloat(value) || 0).toFixed(2)}%`

const defaultCurrency = 'DKK'
export const formatStatMoney = (value, currency) => {
  const [convertedValue, convertedCurrency] = convertMoney(value, currency)
  return `${convertedCurrency || currency || defaultCurrency} ${formatStatValue(
    money.floatToAmount(convertedValue || value || 0),
    true
  )}`
}

export const formatStatObject = (stat) => {
  stat = stat || {}

  const grossClicks = parseInt(stat.gross_clicks) || 0
  const uniqueClicks = parseInt(stat.unique_clicks) || 0
  const nonUniqueClicks = grossClicks - uniqueClicks
  const rejectedClicks = parseInt(stat.rejected_clicks) || 0
  const suspiciousClicks = parseInt(stat.suspicious_clicks) || 0

  const currency = stat.currency || defaultCurrency
  const conversionCount = parseInt(stat.conversions) || 0
  const conversionRate = (conversionCount / (grossClicks || 1)) * 100
  const payout = parseFloat(stat.payout) || 0
  const revenue = parseFloat(stat.revenue) || 0
  const saleAmount = parseFloat(stat.sale_amount) || 0
  const avgSaleAmount = saleAmount / (conversionCount || 1)
  const acquisitionCost = revenue / (conversionCount || 1)
  const profit = saleAmount - payout

  const newCustomerRate = '-'
  const itemsPerOrder = '-'

  return {
    grossClicks,
    uniqueClicks,
    nonUniqueClicks,
    rejectedClicks,
    suspiciousClicks,
    conversionCount,
    conversionRate,
    currency,
    payout,
    revenue,
    saleAmount,
    avgSaleAmount,
    acquisitionCost,
    profit,
    newCustomerRate,
    itemsPerOrder,
  }
}

export const formatStatSumObject = (stats) => {
  const result = {
    grossClicks: 0,
    uniqueClicks: 0,
    nonUniqueClicks: 0,
    rejectedClicks: 0,
    suspiciousClicks: 0,
    conversionCount: 0,
    conversionRate: 0,
    currency: defaultCurrency,
    payout: 0,
    revenue: 0,
    saleAmount: 0,
    avgSaleAmount: 0,
    acquisitionCost: 0,
    profit: 0,
    newCustomerRate: 0,
    itemsPerOrder: 0,
  }

  if (!stats || !stats.length) {
    return result
  }

  for (const stat of stats) {
    const grossClicks = parseInt(stat.gross_clicks) || 0
    const uniqueClicks = parseInt(stat.unique_clicks) || 0
    const nonUniqueClicks = grossClicks - uniqueClicks
    const rejectedClicks = parseInt(stat.rejected_clicks) || 0
    const suspiciousClicks = parseInt(stat.suspicious_clicks) || 0

    const conversionCount = parseInt(stat.conversions) || 0
    const conversionRate = (conversionCount / (grossClicks || 1)) * 100
    const payout = parseFloat(stat.payout) || 0
    const revenue = parseFloat(stat.revenue) || 0
    const saleAmount = parseFloat(stat.sale_amount) || 0
    const avgSaleAmount = saleAmount / (conversionCount || 1)
    const acquisitionCost = revenue / (conversionCount || 1)
    const profit = saleAmount - payout

    result.grossClicks += grossClicks
    result.uniqueClicks += uniqueClicks
    result.nonUniqueClicks += nonUniqueClicks
    result.rejectedClicks += rejectedClicks
    result.suspiciousClicks += suspiciousClicks

    result.conversionCount += conversionCount
    result.conversionRate += conversionRate
    result.payout += payout
    result.revenue += revenue
    result.saleAmount += saleAmount
    result.avgSaleAmount += avgSaleAmount
    result.acquisitionCost += acquisitionCost
    result.profit += profit
  }

  result.currency = stats[0]?.currency
  result.conversionRate /= stats.length

  return result
}
