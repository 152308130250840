export const statTableStyles = `
  .table-header {
    display: inline;
    position: absolute;
    top: 6px;
    left: 8px;
    color: var(--text-primary);
    font-size: 18px;
    font-weight: bold;
  }

  .table-container {
    position: relative;
    overflow-x: auto;
  }

  table {
    thead {
      th {
        min-width: 200px;
      }

      th,
      td {
        text-align: center;
        white-space: nowrap;
      }
    }

    tbody,
    tfoot {
      th,
      td {
        padding-top: 16px;
        padding-bottom: 16px;
      }

      th {
        font-weight: normal;
      }

      td {
        text-align: center;
      }
    }
  }
`
