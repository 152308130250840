import moment from 'moment'

export const shortlinkHost = process.env.SHORTLINK_HOST
export const pdfPath = `${process.env.BACKEND_HOST}/contract`
export const defaultLogoUrl = '/images/sources/publisher-default.png'

export const maxDateFilter = 548

export const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i

export const errorMessages = {
  required: 'Required',
  invalidEmail: 'Invalid e-mail',
}

export const validates = {
  required: { required: errorMessages.required },
  email: {
    pattern: {
      value: emailRegex,
      message: errorMessages.invalidEmail,
    },
  },
}

export const currencies = [
  { label: 'USD', value: 'USD' },
  { label: 'EUR', value: 'EUR' },
  { label: 'DKK', value: 'DKK' },
  { label: 'SEK', value: 'SEK' },
  { label: 'NOK', value: 'NOK' },
  { label: 'GBP', value: 'GBP' },
]

export const commissionModels = [
  { label: 'CPA', value: 'CPA' },
  { label: 'CPC', value: 'CPC' },
  { label: 'CPM', value: 'CPM' },
  { label: 'CPS', value: 'CPS' },
]

export const periodTypes = [
  { label: 'days', value: 'days' },
  { label: 'weeks', value: 'weeks' },
  { label: 'months', value: 'months' },
]

export const billingTypes = [
  {
    label: 'Billing via Heylink',
    value: 'heylink',
    hint: 'Heylink will send both invoice and payment automaticly each month. Tracking reports in Heylink will decide the billable amount.',
  },
  {
    label: 'Self billing',
    value: 'self',
    hint: 'The two parties will handle invoicing themselves and agree upon terms for invoicing.',
  },
]

export const booleanTypes = [
  { label: 'Enabled', value: '1' },
  { label: 'Disabled', value: '0' },
]

export const trackingImplementations = [
  { label: 'Server-to-Server', value: 'Server-to-Server' },
  { label: 'Client-to-Server', value: 'Client-to-Server' },
]

export const filterStatuses = [
  { label: 'Pending', value: 'pending' },
  { label: 'Contract sent', value: 'sent' },
  { label: 'Contract signed', value: 'signed' },
  { label: 'Tracking installed', value: 'installed' },
  { label: 'Running', value: 'running' },
]

export const listProgressStatusLabels = {
  pending: 'Pending',
  sent: 'Contract sent',
  signed: 'Contract signed',
  installed: 'Tracking installed',
  running: 'Running',
  paused: 'Paused',
  ended: 'Ended',
  terminated: 'Terminated',
}

export const listProgressStatusIndex = {
  pending: 1,
  sent: 2,
  signed: 3,
  installed: 4,
  running: 5,
  paused: 0,
  ended: 0,
  terminated: 0,
}
export const listProgressStatusIndexMax = Math.max(
  ...Object.values(listProgressStatusIndex)
)

export const topStatusLabels = {
  pending: 'Pending',
  sent: 'Agreement sent',
  signed: 'Agreement signed',
  installed: 'Tracking installed',
  running: 'Running',
  paused: 'Paused',
  ended: 'Ended',
  terminated: 'Terminated',
}

export const topStatusWaitLabels = {
  sent: 'Waiting for signature',
  signed: 'Waiting for install',
  installed: 'Waiting for first sale',
}

export const cmsOptions = ['Wordpress', 'Shopify', 'Other']

export const conversionStatusMessages = {
  11: 'Known Proxy',
  12: 'Duplicate Conversion',
  13: 'No Referral URL',
  14: 'IP address not whitelisted',
  15: 'Wrong tracking protocol',
  16: 'Affiliate Pixel Loop Detected',
  17: 'Daily Conversion Cap Exceeded',
  18: 'Duplicate Conversion by Unique ID',
  19: 'Invalid Advertiser security token',
  21: 'Employee Test',
  22: 'Affiliate Test',
  23: 'Debug Conversion',
  31: 'Conversion approval enabled',
  41: 'Client cookie tracking',
  42: 'Server cookie tracking',
  43: 'Server postback tracking',
  51: 'Conversion came from RingRevenue',
  52: 'Adjustment (created using Adjust Stats tool)',
  61: 'Monthly offer conversion cap exceeded',
  62: 'Daily payout cap exceeded',
  63: 'Monthly payout cap exceeded',
  64: 'Daily revenue cap exceeded',
  65: 'Monthly revenue cap exceeded',
  81: 'Affiliate monthly conversion cap exceeded',
  82: 'Affiliate daily payout cap exceeded',
  83: 'Affiliate monthly payout cap exceeded',
  84: 'Affiliate daily revenue cap exceeded',
  85: 'Affiliate monthly revenue cap exceeded',
  99: 'Custom status specified on conversion',
  100: 'Conversion Created via Upload',
  101: 'Conversion Updated via Upload',
}

export const defaultCurrency = 'DKK'
export const defaultCountry = 'DK'
export const defaultContractValues = {
  code: '',
  url: '',
  publisherUuids: [],
}
export const defaultContractDataValues = {
  startsAt: moment().toDate(),
  endsAt: undefined,
  currency: defaultCurrency,
  companyCountry: defaultCountry,
  commissionModel: 'CPS',
  commissionPercentage: '10',
  commissionPrice: '100',
  deeplinksEnabled: '1',
  multipleConversionsEnabled: '0',
  conversionRuleSet: 'custom',
  billingType: billingTypes[1].value,
  billingMethod: 'invoice',
  transactionDeclinePeriod: '15',
  transactionDeclinePeriodType: 'days',
  invoicePeriod: '7',
  invoicePeriodType: 'days',
  trackingPeriod: '30',
  trackingPeriodType: 'days',
  trackingImplementation: trackingImplementations[0].value,
}
