import React, { useState, forwardRef } from 'react'
import styled from 'styled-components'
import moment from 'moment'
import SVG from 'react-inlinesvg'
import DatePicker from 'react-datepicker'
import { maxDateFilter } from '../../../utils/constants'
import { saveValue, loadDateValue, forgetValue } from '../../../utils/storage'

const Container = styled.div`
  position: relative;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  height: 50px;
  padding: 7px 0;
  padding-left: 18px;
  padding-right: 44px;
  margin-bottom: 24px;
  border: 1px solid var(--panel-border);
  border-radius: 4px;
  background: white;
  white-space: nowrap;

  svg {
    width: 22px;
    height: 22px;
    margin-right: 10px;
  }

  button.datepicker-input {
    padding: 6px 46px;
    margin: -6px -40px -6px -46px;
    border: none;
    color: var(--text-primary);
    background: transparent;
    font-size: 14px;
  }

  .react-datepicker {
    left: -51px;
    border: none;
    border-radius: 6px;
    box-shadow: 1px 3px 6px 0 rgba(156, 178, 211, 0.5);
    font-family: inherit;

    .react-datepicker__triangle {
      border-bottom-color: var(--body-background);

      &:before {
        border-bottom-color: var(--body-background);
      }
    }

    .react-datepicker__header {
      border: none;
      background: var(--body-background);

      .react-datepicker__current-month {
        color: var(--text-primary);
      }
    }

    .react-datepicker__header__dropdown {
      margin: 10px 0;

      .react-datepicker__month-read-view--down-arrow,
      .react-datepicker__year-read-view--down-arrow {
        top: 7px;
        border-width: 5px;
      }

      .react-datepicker__month-read-view--down-arrow {
        margin-left: 22px;
      }

      .react-datepicker__year-read-view--down-arrow {
        margin-left: 24px;
      }
    }

    .react-datepicker__day--selected {
      background-color: var(--text-primary);

      &:hover {
        background-color: var(--text-primary-hover);
      }
    }

    .react-datepicker__day--disabled,
    .react-datepicker__day--outside-month {
      color: #ccc;
      pointer-events: none;
    }
  }

  svg.arrow {
    position: absolute;
    top: 14px;
    right: 4px;
    width: 18px;
    height: 18px;
    color: var(--text-secondary);
    pointer-events: none;
  }
`

const startDateKey = 'dateRangeStart'
const endDateKey = 'dateRangeEnd'
const lastDateKey = 'dateRangeLast'

const resetDateKeys = () => {
  const lastDateSave = loadDateValue(lastDateKey)
  if (!lastDateSave || lastDateSave.getDate() !== new Date().getDate()) {
    forgetValue(startDateKey)
    forgetValue(endDateKey)
    forgetValue(lastDateKey)
  }
}

export const useDateRangePickerState = () => {
  resetDateKeys()

  const firstDate = moment().subtract(maxDateFilter, 'days')
  const lastDate = new Date()
  const defaultStartDate = moment().subtract(14, 'days').toDate()
  const defaultEndDate = lastDate
  const [startDate, setStartDate] = useState(loadDateValue(startDateKey, null))
  const [endDate, setEndDate] = useState(loadDateValue(endDateKey, null))

  return {
    firstDate,
    lastDate,
    defaultStartDate,
    defaultEndDate,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
  }
}

const dateAsUTC = (date) => {
  if (date) {
    return new Date(
      Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0)
    )
  } else {
    return date
  }
}

export const DateRangePicker = (props) => {
  const { state, onChange } = props

  const {
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    defaultStartDate,
    defaultEndDate,
    firstDate,
    lastDate,
  } = state

  const setDates = (newDates) => {
    const [newStartDateLocal, newEndDateLocal] = newDates

    const newStartDate = dateAsUTC(newStartDateLocal)
    const newEndDate = dateAsUTC(newEndDateLocal)
    setStartDate(newStartDate)
    setEndDate(newEndDate)

    if (newStartDate && newEndDate) {
      saveValue(startDateKey, newStartDate)
      saveValue(endDateKey, newEndDate)
      saveValue(lastDateKey, new Date())
      onChange()
    }
  }

  return (
    <Container>
      <SVG src="/images/icons/calendar.svg" />
      <DatePicker
        selected={startDate}
        onChange={setDates}
        startDate={startDate}
        endDate={endDate}
        minDate={firstDate}
        maxDate={lastDate}
        customInput={
          <DatepickerInput
            startDate={startDate || defaultStartDate}
            endDate={endDate || defaultEndDate}
          />
        }
        shouldCloseOnSelect={false}
        popperPlacement="top-start"
        selectsRange
      />
      <SVG src="/images/icons/arrow-down.svg" className="arrow" />
    </Container>
  )
}

const DatepickerInput = forwardRef((props, _ref) => {
  const { onClick, startDate, endDate } = props

  return (
    <button className="datepicker-input" onClick={onClick}>
      {moment(startDate).format('DD/MM/YYYY')}
      <span> - </span>
      {moment(endDate).format('DD/MM/YYYY')}
    </button>
  )
})
